import Image from 'next/image'
import { type FC, memo } from 'react'

import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'
import { useGetExhibitionPaymentMethodsQuery } from '@redux/features/homePage/homePage.api'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { PaymentMethodsBlockType } from '@Types/blocks/paymentMethods'

import { Container, List } from './styles'

type Props = {
  data: ExtendBlocksType
}

const PaymentMethodsBlock: FC<Props> = ({ data }) => {
  const { payment_methods } = data as PaymentMethodsBlockType
  const { data: paymentMethods } = useGetExhibitionPaymentMethodsQuery(payment_methods)

  return (
    <Container data-block={BlocksKeys.PAYMENT_METHODS}>
      <List>
        {paymentMethods?.map(method => (
          <Image alt={method.label} height="40" key={method.id} loading="lazy" src={method.image} width="120" />
        ))}
      </List>
    </Container>
  )
}

export default memo(PaymentMethodsBlock)
